import { IModalManager } from 'shared/models/modalManager.model';
import ModalManager from 'shared/services/modalManager.service';
import { SsoAuthManagerService } from 'services/auth/ssoAuthManager.service';
import BasesService from 'services/basesManager.service';
import { useUserStore } from 'store/user.store';
import LoginModal from 'components/modals/LoginModal.vue';
import PasswordRecoveryModal from 'components/modals/PasswordRecoveryModal.vue';
import PasswordRecoveryFormModal from 'components/modals/PasswordRecoveryFormModal.vue';
import { AuthManagerService } from 'services/auth/authManager.service';
import QueryParamsService from 'shared/services/queryParams.service';
import { IPasswordModalOptions } from 'shared/models/auth/auth.model';
import { EQueryParam } from 'shared/enums/queryParam.enum';

export async function useServerSsoAuth() {
  if (process.server) {
    try {
      const nuxtApp = useNuxtApp();
      const event = useRequestEvent();
      await SsoAuthManagerService.initSsoLogin(event, nuxtApp, useRuntimeConfig()?.public);
    } catch (error) {
      console.error('Error - useServerSsoAuth: ', error);
    }
  }
}

export function useAuthModal() {
  const route = useRoute();
  const { showLoginModal } = useAuthFnHelper();
  const openLoginModalByQuery = () => route?.query?.[EQueryParam.Auth] && !useUserStore().isUserLoggedIn && showLoginModal(async () => {
    await nextTick(async () => await AuthManagerService.checkSupplierRedirect());
  }, undefined, true, false);

  if (process.client) {
    watch(() => route?.query?.[EQueryParam.Auth], () => openLoginModalByQuery());
  }
  onMounted(async () => route?.query?.[EQueryParam.Auth] && openLoginModalByQuery());
}

export function useAuthFnHelper(customModalManager?: IModalManager) {
  const modalManager = process.client
    ? customModalManager ?? inject<IModalManager>(ModalManager.getServiceName()) ?? ModalManager.getInstance()
    : undefined;
  const basesManager = inject<BasesService>(BasesService.getServiceName());
  const queryParamsService = inject<QueryParamsService>(QueryParamsService.getServiceName());

  async function showLoginModal(
    loginModalCallback?: () => Promise<void> | void,
    passwordRecoveryModalCallback?: () => void,
    clearQueryParams = true,
    isNeedCheckSupplierRedirect = true,
  ): Promise<void> {
    if (await AuthManagerService.checkAuth()) {
      location.reload();
      return;
    }

    const modalName = 'loginModal';
    if (!modalManager) {
      console.warn('modalManager не инициализирован');
    }

    modalManager?.show({
      component: LoginModal,
      bind: {
        name: modalName,
        isNeedCheckSupplierRedirect,
      },
      on: {
        async closeLoginModal(value: boolean) {
          !value && clearQueryParams && await navigateTo({ query: queryParamsService?.getOriginalFiltersQueryParams() }); // clear query params
          await modalManager?.hide(modalName);
          loginModalCallback && await loginModalCallback();
        },
        async clickPasswordRecovery() {
          await modalManager?.hide(modalName);
          showPasswordRecoveryModal(passwordRecoveryModalCallback);
        },
        async successLogin() {
          await nextTick();
          basesManager?.initBases(modalManager);
        },
      },
    });
  }

  function showPasswordRecoveryModal(passwordRecoveryModalCallback?: () => void): void {
    const modalName = 'passwordRecoveryModal';
    modalManager?.show({
      component: PasswordRecoveryModal,
      bind: { name: modalName },
      on: {
        'update:modelValue'() {
          passwordRecoveryModalCallback && passwordRecoveryModalCallback();
          showLoginModal();
        },
      },
    });
  }

  function showPasswordRecoveryFormModal(
    modalTitle: string | undefined,
    token: string,
    passwordRecoverySuccessCallback?: () => void,
    passwordRecoveryFormModalCallback?: () => void,
    options?: IPasswordModalOptions,
  ): void {
    const modalName = 'passwordRecoveryFormModal';
    modalManager?.show({
      component: PasswordRecoveryFormModal,
      bind: {
        name: modalName,
        token,
        modalTitle,
        options,
      },
      on: {
        async close() {
          await passwordRecoveryFormModalCallback?.();
          modalManager.hide(modalName);
        },

        async ok() {
          await passwordRecoverySuccessCallback?.();
          modalManager.hide(modalName);
        },
      },
    });
  }

  return {
    showLoginModal,
    showPasswordRecoveryModal,
    showPasswordRecoveryFormModal,
  };
}
